<template>
    <div>
      <main class="z-0 hidden h-screen w-screen min-w-[1120px] flex-col items-center justify-center gap-0 md:flex">
        <header class="flex h-12 min-h-[48px] w-full items-center justify-between border-b-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-4">
          <button class="flex select-none items-center overflow-hidden rounded-md transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--label-title)] focus:ring-offset-4 focus:ring-offset-[var(--bg-sub)]">
            <img src="../assets/images/logo.png" />
          </button>
  
          <div class="hidden md:ml-6 md:flex md:items-center md:space-x-4">
            
              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-700 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
              <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important; border: none !important;">
                      <span>局部重绘</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/extratoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">局部变款</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/paintingtoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">局部修改</label></a>
                        </div>
                      </form>
                      </PopoverPanel>
                    </transition>
              </Popover>
          <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important; border: none !important;">
                      <span>手稿配色</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/imgtocanny"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">图转线稿</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/cannytoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">手稿配色</label></a>
                        </div>
                      </form>
                      </PopoverPanel>
                    </transition>
              </Popover>   
              <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important;
      border: none !important;">
                      <span>款式广场</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
  
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/mypic"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">我的作品</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/myfav"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">我的收藏</label></a>
                        </div>
                        <div class="flex items-center">
                        <a href="/pic"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">款式广场</label></a>
                        </div>
                       
                      </form>
                      </PopoverPanel>
                    </transition>
                  </Popover>
            </div>
            
  
          <div class="flex items-center gap-4">
            <div class="flex items-center gap-2">
              <button type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 pl-1.5 pr-2 h-7 rounded-lg gap-1 text-xs border-none bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)] cursor-pointer" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r4r:" data-state="closed" data-type="primary">
              <div class="flex items-center h-4 w-4">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.232 2.287A.75.75 0 0 1 13.75 3v6.25H19a.75.75 0 0 1 .607 1.191l-8 11a.75.75 0 0 1-1.357-.44v-6.25H5a.75.75 0 0 1-.607-1.192l8-11a.75.75 0 0 1 .839-.272Z" fill="currentColor"></path>
                </svg>
              </div>
              <span>{{picnum.value}}</span>
            </button> 
            </div>
            <div class="h-4 w-px bg-[var(--bg-shade)]"></div>
            <div class="flex items-center gap-2">
              <Popover class="relative inline-block px-4 text-left">
              <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important;
    border: none !important;">
             <button type="button" class="select-none whitespace-nowrap font-medium transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 pl-2 pr-3 h-8 gap-1.5 text-sm border border-solid border-[var(--bg-border)] bg-[var(--bg-base-hover)] text-[var(--label-base)] hover:bg-[var(--bg-shade)] hover:text-[var(--label-title)] focus:ring-[var(--label-title)] cursor-pointer z-50 flex items-center justify-center focus:outline-none rounded-full" data-state="closed" data-type="secondary">
              <!-- <div class="flex items-center h-5 w-5">
                <img alt="Credit Icon" loading="lazy" width="20" height="20" decoding="async" data-nimg="1" class="object-contain" srcset="../assets/images/jp.png" src="../assets/images/jp.png" style="color: transparent;">
              </div>
              <span class="tabular-nums">200</span> -->
              会员中心
            </button> 
          
            <!-- <button type="button" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r4v:" data-state="closed" class="ml-1 rounded-full transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--label-title)] focus:ring-offset-2 focus:ring-offset-[var(--bg-sub)]">
              <span class="relative inline-flex select-none items-center justify-center overflow-hidden rounded-full align-middle h-8 w-8 min-w-[32px]">
                <img class="h-full w-full rounded-full border-[0.5px] border-[var(--bg-border)] object-cover"  src="../assets/images/server.png">
              </span>
            </button> -->
                  
            </PopoverButton>
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <form class="space-y-4">
                        <div class="flex items-center">
                       <a href="/profile"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">会员中心</label></a> 
                      </div>
                      <div class="flex items-center">
                       <a href="/repassword"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">修改密码</label></a>
                      </div>
                      <div class="flex items-center">
                      <a href="/conlog"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">消费记录</label></a>
                      </div>
                      <div class="flex items-center">
                      <a href="/?ac=loginout"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">退出登录</label></a>
                      </div>
                    </form>
                    </PopoverPanel>
                </transition>
            </Popover>
            </div>
          </div>
        </header>
        <div class="relative flex h-full max-h-[calc(100%-48px)] w-full justify-end">
          <!--左边菜单 start  <div class="w-17 mt-4 ml-4 mb-4 min-w-[288px] max-w-[368px] border-[0.5px] flex-col items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md">-->
        <div class="absolute inset-y-4 left-4 z-10 flex w-[16%] min-w-[308px] max-w-[338px] flex-col items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md" data-projection-id="9" style="opacity: 1; transform: none;">
          <!-- <div class="w-17 mt-4 ml-4 mb-4 min-w-[288px] max-w-[368px] border-[0.5px] flex-col items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md" data-projection-id="9" style="opacity: 1; transform: none;"> -->
          <div class="relative flex h-11 min-h-[44px] w-full items-center justify-center border-b-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)]">
            <button  @click="delpic" type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 w-8 h-8 rounded-[10px] text-base border-none bg-transparent hover:bg-white/5 hover:text-[var(--label-title)] focus:ring-[var(--label-title)] cursor-pointer absolute right-6 text-[var(--label-muted)]" data-type="icon-tertiary">
                <div class="flex items-center h-5 w-5">
                    <img src="../assets/images/clear.png" width="18"/>
                  </div>
            </button>
            <button  @click="openFileInput" type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 w-8 h-8 rounded-[10px] text-base border-none bg-transparent hover:bg-white/5 hover:text-[var(--label-title)] focus:ring-[var(--label-title)] cursor-pointer absolute right-2 text-[var(--label-muted)]" data-type="icon-tertiary">
                <div class="flex items-center h-5 w-5">
                    <img src="../assets/images/ups.png" width="25"/>
                  </div>
             </button>
                <div class="flex items-center gap-2 text-[var(--accent-base)]">
                  
                  <div class="select-none text-base font-medium">款式再创</div>
                </div>
                
              </div>
              <div dir="ltr" class="relative flex h-full w-full overflow-auto" style="position: relative; --radix-scroll-area-corner-width: 0px; --radix-scroll-area-corner-height: 0px;">
                
                <div class="w-full relative inner" style="overflow: hidden scroll;">
                  <div style="min-width: 100%; display: table;">
                    <div class="flex w-full flex-col gap-6 p-4">

                      <div class=" flex w-full flex-col items-start justify-center gap-3">
                           <div class="picbg w-full" style="border-radius: 25px;height:250px"></div>
                           <div class="absolute left-6 top-4 yq"   v-show="picshow">
                                  <img :src="thumbnailBase64.value">
                            </div>
                           <div class="absolute left-6 top-6 " style="width:250px;height:250px;" v-show="!picshow">
                           <div  class="drop-area" @dragover.prevent="highlight" @dragenter.prevent="highlight" @dragleave.prevent="unhighlight" @drop.prevent="handleDrop" :class="{ 'highlighted': isHighlighted }">
                           <p>拖拽文件到此处上传<Br/> <button @click="openFileInput">选择文件</button></p>
                         
                           </div>
                           <input type="file" ref="fileInput" style="display: none" @change="handleFileInput"/>
                            
                          </div>
                            
                      </div>
                      <div class="flex w-full items-start justify-center gap-2 rounded-xl border px-3 py-2 border-[var(--semantic-info-border)] bg-[var(--semantic-info-bg)]" data-projection-id="10" style="opacity: 1; transform: none;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5 min-w-[20px] text-[var(--accent-base)]">
                              <path d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z" fill="currentColor" stroke-width="0"></path>
                            </svg>
                            <div class="w-full whitespace-normal text-start text-xs font-normal text-[var(--accent-base)]">
                              <span>
                                <span>上传款式图片。实现变款！</span>
                              </span>
                            </div>
                      </div>
                      <div class="flex w-full flex-col items-start justify-center gap-3" style="display:none">
                        <div class="select-none text-base font-semibold text-[var(--label-title)]">相似度（值越大变化越大）</div>
                        <div role="radiogroup" aria-required="false" dir="ltr" class="grid w-full grid-cols-2 gap-2" tabindex="0" style="outline: none;">
                        <input aria-label="Brush radius" type="range" min="0.3" max="0.7" step="0.2" class="sveltes" v-model="brushsize">
                        <input type="text" class="form-input" v-model="brushsize" >
                        </div>
                      </div>

                      <RadioGroup v-model="selectnum" class="mt-2" style="display:none">
                         <div class="grid grid-cols-3 gap-3 sm:grid-cols-3">
                         <RadioGroupOption as="template" v-for="option in memoryOptions" :key="option.name" :value="option" :disabled="!option.inStock" v-slot="{ active, checked }">
                         <div :class="[option.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', checked ? 'bg-[var(--accent-base)] border-transparent text-[var(--accent-label)] hover:bg-[var(--accent-base)]' : 'bg-white border-gray-200 text-gray-900 hover:bg-[var(--accent-base)]', 'border rounded-md px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1']">
                         <RadioGroupLabel as="span">{{ option.name }}</RadioGroupLabel>
                         </div>
                         </RadioGroupOption>
                         </div>
                      </RadioGroup>

                      <RadioGroup v-model="selectpicsize" class="mt-2" style="display:none">
                         <div class="grid grid-cols-3 gap-3 sm:grid-cols-3">
                         <RadioGroupOption as="template" v-for="option in picsize" :key="option.name" :value="option" :disabled="!option.inStock" v-slot="{ active, checked }">
                         <div :class="[option.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', checked ? 'bg-[var(--accent-base)] border-transparent text-[var(--accent-label)] hover:bg-[var(--accent-base)]' : 'bg-white border-gray-200 text-gray-900 hover:bg-[var(--accent-base)]', 'border rounded-md py-1  flex items-center justify-center text-sm font-medium uppercase sm:flex-1']">
                         <RadioGroupLabel as="span">{{ option.name }}</RadioGroupLabel>
                         </div>
                         </RadioGroupOption>
                         </div>
                      </RadioGroup>
                      <div class="flex w-full flex-col items-start justify-center gap-3" style="display:none">
                        <div class="flex w-full items-center justify-between">
                          <div class="select-none text-base font-semibold text-[var(--label-title)]">提示词</div>
                          <button @click="deltxt" type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 px-0 gap-1 h-6 text-xs border-none bg-transparent text-[var(--accent-base)] hover:text-[var(--accent-highlight)] hover:underline focus:underline focus:ring-transparent cursor-pointer" data-type="link">
                            <div class="flex items-center h-4 w-4">
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="tabler-icon tabler-icon-refresh">
                                <path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
                                <path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
                              </svg>
                            </div>清空重填
                          </button>
                        </div>
                        <div class="flex w-full flex-col items-start justify-center gap-2">
                          <div class="flex w-full flex-col items-start justify-center gap-2">
                            <div data-status="default" class="style_TextArea__xP7Uv group relative w-full rounded-xl border border-solid border-[var(--bg-border)] bg-[var(--bg-base)] px-3 pt-2 transition duration-100 ease-out focus-within:border-[var(--accent-base)] focus-within:outline-none hover:border-[var(--bg-border-strong)] focus-within:hover:border-[var(--accent-base)]">
                              <textarea id="style" placeholder="描述您想要的对象风格，例如古代风格、颜色、类型、款式。" class="w-full resize-none bg-transparent text-sm font-normal text-[var(--label-base)] caret-[var(--accent-base)] outline-none placeholder:select-none placeholder:text-[var(--label-faint)]" rows="5" v-model="prompt"></textarea>
                              <div data-status="default" class="style_TextCount__sAvP6 flex h-6 w-full select-none items-start justify-end bg-[var(--bg-base)] text-right align-middle text-xs font-normal text-[var(--label-faint)] transition duration-100 ease-out">2 / 500</div>
                            </div>
                          </div>
                          <div class="flex w-full items-start justify-center gap-2 rounded-xl border px-3 py-2 border-[var(--semantic-info-border)] bg-[var(--semantic-info-bg)]" data-projection-id="10" style="opacity: 1; transform: none;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="h-5 w-5 min-w-[20px] text-[var(--semantic-info-base)]">
                              <path d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z" fill="currentColor" stroke-width="0"></path>
                            </svg>
                            <div class="w-full whitespace-normal text-start text-xs font-normal text-[var(--semantic-info-highlight)]">
                              <span>
                                <span>上传款式图片。实现变款！</span>
                              </span>
                            </div>
                          </div>
                          <div class="flex items-baseline gap-2">
                            <label class="select-none text-sm font-medium text-[var(--label-base)]" for="negative-prompt">反向提示词</label>
                            <div class="select-none text-xs font-normal text-[var(--label-muted)]">（选填）</div>
                          </div>
                          <div class="flex w-full flex-col items-start justify-center gap-2">
                            <div data-status="default" class="style_TextArea__xP7Uv group relative w-full rounded-xl border border-solid border-[var(--bg-border)] bg-[var(--bg-base)] px-3 pt-2 transition duration-100 ease-out focus-within:border-[var(--accent-base)] focus-within:outline-none hover:border-[var(--bg-border-strong)] focus-within:hover:border-[var(--accent-base)]">
                              <textarea id="negative-prompt" placeholder="描述您不希望在图片中看到的内容，例如低质量、模糊、丑陋、不自然的色彩" class="w-full resize-none bg-transparent text-sm font-normal text-[var(--label-base)] caret-[var(--accent-base)] outline-none placeholder:select-none placeholder:text-[var(--label-faint)]" rows="3" v-model="negprompt"></textarea>
                              <div data-status="default" class="style_TextCount__sAvP6 flex h-6 w-full select-none items-start justify-end bg-[var(--bg-base)] text-right align-middle text-xs font-normal text-[var(--label-faint)] transition duration-100 ease-out">0 / 500</div>
                            </div>
                          </div>
                          
                        </div>
                      </div>
                      <div class="flex w-full flex-col items-start justify-center gap-3" >
                        <div class="select-none text-base font-semibold text-[var(--label-title)]">变化权重</div>
                        <div role="radiogroup" aria-required="false" dir="ltr" class="grid w-full grid-cols-2 gap-2" tabindex="0" style="outline: none;">
                <input aria-label="Brush radius" type="range" min="0.25" max="0.7" step="0.05" class="sveltes" v-model="scalesize">
                <input type="text" class="form-input" v-model="scalesize" >
                        </div>
                      </div>
    
                      <!-- <RadioGroup v-model="selectnum" class="mt-2" >
                           <div class="grid grid-cols-3 gap-3 sm:grid-cols-3">
                           <RadioGroupOption as="template" v-for="option in memoryOptions" :key="option.name" :value="option" :disabled="!option.inStock" v-slot="{ active, checked }">
                           <div :class="[option.inStock ? 'cursor-pointer focus:outline-none' : 'opacity-25 cursor-not-allowed', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', checked ? 'bg-indigo-600 border-transparent text-white hover:bg-indigo-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50', 'border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1']">
                           <RadioGroupLabel as="span">{{ option.name }}</RadioGroupLabel>
                           </div>
                           </RadioGroupOption>
                           </div>
                      </RadioGroup>
     -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex w-full flex-col items-center justify-center gap-3 border-t-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-4 py-3">
                <!-- <div class="flex w-full flex-col gap-0.5">
                  <div class="flex items-center justify-between gap-1 text-center text-xs font-normal">
                    <span class="text-[var(--label-muted)]">生成大概时间: </span>
                    <span>
                      <span class="text-[var(--label-base)]">20</span>
                      <span class="text-[var(--label-base)]"> 秒</span>
                    </span>
                  </div>
                  <div class="flex items-center justify-between text-center text-xs font-normal">
                    <span class="text-[var(--label-muted)]">扣点: </span>
                    <span>
                      <img alt="Credit Icon" loading="lazy" width="12" height="12" decoding="async" data-nimg="1" class="mx-1 inline-flex" srcset="../assets/images/732.png" src="../assets/images/732.png" style="color: transparent;">
                      <span class="mr-1 text-[var(--label-base)]">10</span>
                      <span class="text-[var(--label-base)]">币</span>
                    </span>
                  </div>
                </div> -->
                <button type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 pl-3 pr-4 h-10 rounded-xl gap-2 w-full text-base border-none bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)] cursor-pointer" data-type="primary" @click="makepic">
                  <div class="flex items-center h-6 w-6">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5Zm9-3a.75.75 0 0 1 .728.568l.258 1.036a2.63 2.63 0 0 0 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258a2.63 2.63 0 0 0-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.624 2.624 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395a1.5 1.5 0 0 0-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395a1.5 1.5 0 0 0 .948-.948l.395-1.183a.75.75 0 0 1 .71-.513Z" fill="currentColor"></path>
                    </svg>
                  </div>生成图片
                </button>
              </div>
            </div>
          <!--左边菜单 end-->
          <!--中间开始 start-->
          <div class="main-box mb-4" style="margin-left:338px">
            <div class="h-full max-h-[calc(100%-148px)] flex items-center gap-3 rounded-3xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-3 shadow-md mt-4 mb-4" data-projection-id="12" style="opacity: 1; transform: none;">
              <div class="relative flex h-full h-full w-full items-center justify-center" style="">
                <div style="position: relative; width: 100%; height: 100%;  pointer-events: auto; touch-action: none;display:flex;justify-content:center;align-items:center;overflow:hidden" >
                    <!-- <div class="picbg" style="position: absolute;top:15px;left:5px;right:5px;bottom:15px;border-radius: 25px;"></div>
                    <canvas data-engine="three.js r152" style="display: block; width: 100%; height: 100%;overflow:hidden"></canvas> -->
                    <div style="display: block; width: 100%; height: 100%;overflow:hidden"><img :src="hpic.value" style="width:auto;height:auto;max-width:100%;max-height:100%;object-fit:contain;display:block;margin:auto"/></div>
               </div> 
               
               </div>
            </div>
            <!--底部图片列表 start-->
            <div v-show="hpic" class="absolute bottom-4 z-10 flex h-[104px] items-center gap-3 rounded-3xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-3 shadow-md" data-projection-id="12" style="opacity: 1; transform: none;left:23%">
              <div class="relative" >
                <button tabindex="0" class="group relative flex h-20 w-20 flex-col items-center justify-center overflow-hidden rounded-xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-base-hover)] p-2 transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--accent-base)] focus:ring-offset-2 focus:ring-offset-[var(--bg-sub)] active:opacity-70">
                  <img :src="hpic.value" alt="3D View" class="absolute inset-0 h-full w-full object-cover">
                  <div class="absolute inset-0 z-10 h-full w-full bg-[var(--bg-modal-overlay)] opacity-0 transition duration-300 ease-out group-hover:opacity-100"></div>
                  
                </button>
                <div class="pointer-events-none absolute left-0 top-0 z-10 h-20 w-20 rounded-xl ring-2 ring-[var(--accent-base)] ring-offset-2 ring-offset-[var(--bg-sub)] focus:outline-none" data-projection-id="13" style="opacity: 1; transform: none;"></div>
              </div>
              <div class="h-4 w-1 rounded-full bg-[var(--bg-border)]"></div>
              <div class="relative -mx-3 flex h-full flex-col items-center justify-start overflow-hidden">
                <div class="mx-3 flex h-fit flex-col items-center justify-center gap-6 overflow-visible py-3" data-projection-id="14" style="transform: none;">
                  <div class="flex items-center gap-3 overflow-visible focus:outline-none">
                    <button v-for="(item,index) in piclist" tabindex="0" class="group relative flex h-20 w-20 flex-col items-center justify-center overflow-hidden rounded-xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-base-hover)] p-2 transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--accent-base)] focus:ring-offset-2 focus:ring-offset-[var(--bg-sub)] active:opacity-70" @click="clickpic(item.bigpath)">
                      <img :src="item.picpath" :alt="item.picname" class="absolute inset-0 h-full w-full object-cover">
                      <div class="absolute inset-0 z-10 h-full w-full bg-[var(--bg-modal-overlay)] opacity-0 transition duration-300 ease-out group-hover:opacity-100"></div>
                      
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
                    <!--底部图片列表 end-->
          </div>
          <!--中间开始 end-->
                <!--右边菜单 start-->
          <div class="w-28 mt-4 mr-4 mb-4 min-w-[308px] max-w-[338px] border-[0.5px] items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md" style="display:none">
            
            <div dir="ltr" class="relative flex h-full w-full overflow-auto" style="position: relative; --radix-scroll-area-corner-width: 0px; --radix-scroll-area-corner-height: 0px;">
              
              <div data-radix-scroll-area-viewport="" class="w-full" style="overflow: hidden scroll;">
                <div style="min-width: 100%; display: table;">
                  <div class="flex w-full flex-col gap-6 p-4">
                    <div class="flex w-full flex-col items-start justify-center gap-3">
                      <div class="select-none text-base font-semibold text-[var(--label-title)]">选择颜色</div>
                      <RadioGroup v-model="selectedColor">
                                  <div class="mt-4 flex items-center space-x-3">
                                      <RadioGroupOption as="template" v-for="color in colors" :key="color.name" :value="color" v-slot="{ active, checked }">
                                                 <div :class="[color.selectedColor, active && checked ? 'ring ring-offset-1' : '', !active && checked ? 'ring-2' : '', '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none']">
                                                 <RadioGroupLabel as="span" class="sr-only" title="abc">{{ color.name }}</RadioGroupLabel>
                                                 <span aria-hidden="true" :class="[color.bgColor, 'h-8 w-8 border border-black border-opacity-10 rounded-full']" />
                                                 </div>
                                      </RadioGroupOption>
                                   </div>
                      </RadioGroup>
                      <RadioGroup v-model="selectedColor">
                                  <div class="mt-4 flex items-center space-x-3">
                                      <RadioGroupOption as="template" v-for="color in colors2" :key="color.name" :value="color" v-slot="{ active, checked }">
                                                 <div :class="[color.selectedColor, active && checked ? 'ring ring-offset-1' : '', !active && checked ? 'ring-2' : '', '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none']">
                                                 <RadioGroupLabel as="span" class="sr-only" title="abc">{{ color.name }}</RadioGroupLabel>
                                                 <span aria-hidden="true" :class="[color.bgColor, 'h-8 w-8 border border-black border-opacity-10 rounded-full']" />
                                                 </div>
                                      </RadioGroupOption>
                                   </div>
                      </RadioGroup>
                    </div>
                    
                    <div class="w-full items-center justify-between">
                      <div class="flex items-center gap-2">
                        <label class="select-none text-base font-semibold text-[var(--label-title)]" for="generate-pbr-maps">选择素材</label>
                        <button data-state="closed" class="z-50 flex items-center justify-center focus:outline-none">
                          <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-[var(--label-faint)] transition duration-100 ease-out hover:text-[var(--label-muted)]">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22a10 10 0 1 0 0-20 10 10 0 0 0 0 20Zm1.25-13.75a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm-2.5 2.5a.938.938 0 0 0 0 1.875h.313v2.5h-1.25a.938.938 0 0 0 0 1.875h4.374a.938.938 0 0 0 0-1.875h-1.25V10.75H10.75Z" fill="currentColor"></path>
                          </svg>
                        </button>
                      </div>
                     <div class="w-full flex items-center gap-2">
                        <select v-model="selectedmaterial" class="bg-white mr-4 w-32 py-2 px-3 mt-1 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 shadow-sm">
                                    <option v-for="option in options" :value="option.value">
                                            {{ option.text }}
                                     </option>
                                    </select>
                                    <select v-model="selectedelement" class="bg-white mr-4 w-32 py-2 px-3 mt-1 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 shadow-sm">
                                    <option v-for="element in elements" :value="element.value">
                                            {{ element.text }}
                                     </option>
                                    </select>
                                    <select v-model="selectedhead" class="bg-white w-32 py-2 px-3 mt-1 rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 shadow-sm">
                                    <option v-for="head in heads" :value="head.value">
                                            {{ head.text }}
                                     </option>
                                    </select>
                     </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
            <!--右边菜单 end-->
        </div>
      </main>
      <div class="flex fixed items-center p-6 max-w sm mx-auto bg-gray-200 rounded-lg space-x-4 a z-10 " style="left:45%;top:40%" v-show="onshow.value">
          <div>
            <div class="text-xl font-medium text-black">{{message}}</div>
            <p class="text-slate-500">
              <button type="button" class="bg-white mr-4 w-32 py-2 px-3 mt-1 rounded-md border border-gray-300" @click="clicle">确 定</button>
            </p>
          </div>
     </div>
      </div>
    </template>
    <style>
    [data-radix-scroll-area-viewport]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:touch;}[data-radix-scroll-area-viewport]::-webkit-scrollbar{display:none}
    
    </style>
    
    <script setup>
    import { modellist,imgtoimg,vitoken,picinfo,userinfo } from '../api/ajaxApi';
    import {setCookie,getCookie,delCookie} from '../utils/cookieok';
    import {Msg} from '@/utils/tools';
    import { RadioGroup, RadioGroupLabel, RadioGroupOption,Popover, PopoverButton, PopoverGroup, PopoverPanel,Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
    import {
    ArrowPathIcon,
    Bars3Icon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    DocumentChartBarIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
    } from '@heroicons/vue/24/outline'
    import { useRouter } from 'vue-router'
    const router = useRouter()
    const route = router.value;
    import { ChevronDownIcon } from '@heroicons/vue/20/solid'
    import {
        ref,
        onMounted, // 组件加载完成时调用
        onUpdated, // 组件更新完成时调用
        onUnmounted, // 组件卸载完成时调用
        onBeforeMount, // 组件加载前时调用
        onBeforeUpdate, // 组件更新前时调用
        onBeforeUnmount, // 组件卸载前时调用
        onActivated, // 组件激活时时调用
        onDeactivated, // 组件失活时时调用
        onErrorCaptured, // 捕获到后代组件的错误时调用
        onRenderTracked, // 状态跟踪，(组件更新时，跟踪组件里边每个值和方法的变化)
        onRenderTriggered, // 状态触发，(虚拟 DOM 重新渲染被触发时调用,此事件告诉你是什么操作触发了重新渲染，以及该操作的目标对象和键)
        onServerPrefetch, // 服务器上被渲染之前调用
    } from "vue";
    
    const selectedmaterial=ref(0); // 存储所选值的变量元素
    const options=ref([ // 下拉列表的选项
                  { value: '0', text: '选择元素' },
                  { value: '1', text: '蝴蝶结' },
                  { value: '2', text: '立体花' }
                ]);
    const selectedelement=ref(0);//材质
    const elements=ref([ 
                 { value: '0', text: '选择材料' },
                  { value: '3', text: '蛇纹' },
                  { value: '4', text: '牛仔布' },
                  {value: '7', text: ' 鳄鱼纹' },
                  {value:'8',text:'豹纹'},
                  {value:'9',text:'蕾丝面料'},
                  {value:'10',text:'格纹面料'}
                ]);
    const selectedhead=ref(0)
    const heads=ref([ 
                { value: '0', text: '选择头型 ' },
                { value: '6', text: '尖头' },
                { value: '5', text: '方头' }]);
    
    const colors = [
      { name: 'white',bgColor:'bg-white-500',selectedColor: 'ring-white-500',value:'1' },
      { name: 'black', bgColor: 'bg-black-500', selectedColor: 'ring-black-500',value:'2' },
      { name: 'brown',bgColor:'bg-brown-500',selectedColor: 'ring-brown-500',value:'3' },
      { name: 'red',bgColor:'bg-red-500',selectedColor: 'ring-red-500',value:'4' },
      { name: 'Yellow', bgColor: 'bg-yellow-500', selectedColor: 'ring-yellow-500',value:'5' },
      { name: 'Purple', bgColor: 'bg-purple-500', selectedColor: 'ring-purple-500',value:'6' },
      
    ]
    const colors2 = [
    { name: 'Blue', bgColor: 'bg-blue-500', selectedColor: 'ring-blue-500',value:'7' },
      { name: 'gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500',value:'8' },
      { name: 'Green', bgColor: 'bg-green-500', selectedColor: 'ring-green-500',value:'9' },
      { name: 'pink',bgColor:'bg-pink-500',selectedColor: 'ring-pink-500',value:'10' }
    ]
    
    const navigation = [
    { name: '首页', href: '/', current: false }, 
    { name: '图文创款', href: '/txttoimg', current: false }, 
    { name: '款式再创', href: '/imgtoimg', current: true }, 
    { name: '款式融合', href: '/styletoimg', current: false }, 
    // { name: '手稿配色', href: '/cannytoimg', current: false },
    // { name: '图转线稿', href: '/imgtocanny', current: false }, 
    // { name: '局部重绘', href: '/extratoimg', current: false },
    { name: '换色换料', href: '/colortoimg', current: false },
    { name: '智能抠图', href: '/rembg', current: false },
  ]
    const selectedColor = ref(colors[1])  //颜色
  
    const trendylist=ref([]);
    const casuallist=ref([]);
    const fashionlist=ref([]);
    let thumbnailBase64=ref('');
    let picshow=ref(false);
    const brushsize=ref(0.5);

    const onshow=ref(false);
    const message=ref("");
    const picnum=ref("");
    function clicle(){
     onshow.value=!onshow.value
    }
    function delpic(){
      picshow.value=!picshow.value;
    }
    function deltxt(){
    prompt.value=''
    }
    const memoryOptions = [
    { name: '1 张',value:1, inStock: true },
    { name: '2 张',value:2, inStock: true },
    { name: '4 张',value:4, inStock: true },
  ]

  const picsize = [
    { name: '512*512',value:1, inStock: true },
    { name: '1024*1024',value:2, inStock: true },
    { name: '2048*2048',value:3, inStock: true },
  ]
  //图片上传处理开始
  const fileInput = ref(null);
  const handleDrop = (event) =>{
    const file = event.dataTransfer.files[0];
    const base64String=event.target.result;
    conlog.log(base64String)
    var image = new Image();
    console.log(file.type)
   if(file.type != 'image/png'&&file.type != 'image/jpeg'&&file.type != 'image/gif'&&file.type != 'image/webp'){
          return false;
     }
    
   if(false){}else{
   
        image.src = window.URL.createObjectURL(file);
        image.onload = function(){
          var width = image.width,height = image.height
            if (width > 1200 || height > 1200) {
                const maxDimension = 1200;
                // 按比例缩放
                if (width > height) {
                    height = height * (maxDimension / width);
                    width = maxDimension;
                } else {
                    width = width * (maxDimension / height);
                    height = maxDimension;
                }
            }
            // 默认图片质量为0.7，quality值越小，所绘制出的图像越模糊
            var quality = 1;
            //生成canvas
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // 创建属性节点
            var anw = document.createAttribute("width");
            anw.nodeValue = width;
            var anh = document.createAttribute("height");
            anh.nodeValue = height;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);

            ctx.drawImage(image, 0, 0, width, height);
            var ext = image.src.substring(image.src.lastIndexOf(".")+1).toLowerCase();//图片格式
            var base64 = canvas.toDataURL("image/"+ext, quality );
                thumbnailBase64.value=ref(base64String);
                picshow.value=ref(true);
                // 回调函数返回base64的值
            }
        }
  }
  const openFileInput = () => {
    fileInput.value.click();
   };
   const handleFileInput = (event) => {
   const file = event.target.files[0];
   const base64String=event.target.result;
   var image = new Image();
  //  if(file.type != 'image/png'&&file.type != 'image/jpeg'&&file.type != 'image/gif'&&file.type != 'image/webp'){
  //         return false;
  //  }
   if(false){}else{
        image.src = window.URL.createObjectURL(file);
        image.onload = function(){
            var width = image.width,height = image.height
            if (width > 1200 || height > 1200) {
                const maxDimension = 1200;
                // 按比例缩放
                if (width > height) {
                    height = height * (maxDimension / width);
                    width = maxDimension;
                } else {
                    width = width * (maxDimension / height);
                    height = maxDimension;
                }
            }
            // 默认图片质量为0.7，quality值越小，所绘制出的图像越模糊
            var quality = 1;
            //生成canvas
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // 创建属性节点
            var anw = document.createAttribute("width");
            anw.nodeValue = width;
            var anh = document.createAttribute("height");
            anh.nodeValue = height;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);

            ctx.drawImage(image, 0, 0, width, height);
            var ext = image.src.substring(image.src.lastIndexOf(".")+1).toLowerCase();//图片格式
            var base64 = canvas.toDataURL("image/"+ext, quality );
                thumbnailBase64.value=ref(base64);
                picshow.value=ref(true);
                // 回调函数返回base64的值
            }
        }
    }
  //图片上传处理结束
  function hrefurl(url){
    router.push(url);
  }

  onMounted(() => {
    document.title = 'AI鞋款创意设计工具';
    binstyle()
   
  })

  function resizeImage(imageFile, callback) {
    const reader = new FileReader();
    
    reader.onload = function(event) {
        const img = new Image();
        img.onload = function() {
            let width = img.width;
            let height = img.height;

            // 判断图片的最大边是否超过1200像素
            if (width > 1200 || height > 1200) {
                const maxDimension = 1200;
                // 按比例缩放
                if (width > height) {
                    height = height * (maxDimension / width);
                    width = maxDimension;
                } else {
                    width = width * (maxDimension / height);
                    height = maxDimension;
                }
            }

            // 创建一个canvas来绘制缩放后的图片
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext("2d");

            ctx.drawImage(img, 0, 0, width, height);

            // 获取base64编码的图片数据
            const base64String = canvas.toDataURL(imageFile.type);

            // 调用回调函数，传递base64编码的图片
            callback(base64String);
        };
        img.src = event.target.result;
    };

    reader.readAsDataURL(imageFile);
}
  function binstyle(){
    let params={
        token:getCookie("usertoken"),
    }
    vitoken(params).then(res => {
        if (res.success) {
          userinfo(params).then(ress=>{
                 if(ress.success){
                    picnum.value=ref(ress.data.picnum)
                    setCookie('userinfo', ress.data, 7)
                 }
            })
        }
        else
        {
            router.push('/login');
        }
        })
        modellist(params).then(res => {
        if (res.success) {
            trendylist.value = res.data.trendy.map(item => ({ ...item, style: 'border-gray-300' }));
            casuallist.value=res.data.casual.map(item => ({ ...item, style: 'border-gray-300' }));
            fashionlist.value=res.data.fashion.map(item => ({ ...item, style: 'border-gray-300' }));
        }
        else
        {
            router.push('/login');
        }
    })
  }

  const tabs = [
    {name: '潮鞋'},
    {name: '休闲鞋',},
    {name: '时装鞋',}
  ]
  const selectnum = ref(memoryOptions[1])//数量
  const selectpicsize=ref(picsize[0])
  const negprompt=ref("")//反提示词
  const prompt=ref("")   //提示词
  const scalesize=ref(0.35)   //权重
  const stheme=ref(0)  
  function selecttheme(theme){
    stheme.value=ref(theme)  //主题
  }
  
  const piclist=ref([])
  const hpic=ref("")
  function clickpic(pic){
    hpic.value=ref(pic)
  }
  function makepic(){
        if(thumbnailBase64.value.value==null){
          onshow.value=ref(true)
          message.value="请先上传图片"
        }
        else{
             Msg.loading();
             let params={
                bigclass:stheme.value,
                prompt:prompt.value,
                negprompt:negprompt.value,
                colors:selectedColor.value.value,
                num:selectnum.value.value,
                size:selectpicsize.value.value,
                scale:scalesize.value,
                material:selectedmaterial.value,
                element:selectedelement.value,
                head:selectedhead.value,
                b64:thumbnailBase64.value.value,
                brushsize:brushsize.value,
                token:getCookie("usertoken")
            }
            imgtoimg(params).then(res => {
                Msg.hideLoading();
               if (res.success) {
                    piclist.value=res.data.concat(piclist.value)
                    hpic.value=ref(res.data[0].bigpath)
                    binstyle()
                }
                else{
                    onshow.value=ref(true)
                    message.value=res.msg
                }
             })
           }
           }
    </script>
    
 