<template>
    <div>
      <main class="z-0 hidden h-screen w-screen min-w-[1120px] flex-col items-center justify-center gap-0 md:flex">
        <header class="flex h-12 min-h-[48px] w-full items-center justify-between border-b-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-4">
          <button class="flex select-none items-center overflow-hidden rounded-md transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--label-title)] focus:ring-offset-4 focus:ring-offset-[var(--bg-sub)]">
            <img src="../assets/images/logo.png" />
          </button>
  
          <div class="hidden md:ml-6 md:flex md:items-center md:space-x-4">
              
              <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-700 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
              <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important; border: none !important;">
                      <span>局部重绘</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/extratoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">局部变款</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/paintingtoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">局部修改</label></a>
                        </div>
                      </form>
                      </PopoverPanel>
                    </transition>
              </Popover>
          <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important; border: none !important;">
                      <span>手稿配色</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/imgtocanny"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">图转线稿</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/cannytoimg"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">手稿配色</label></a>
                        </div>
                      </form>
                      </PopoverPanel>
                    </transition>
              </Popover>
              <Popover class="relative inline-block px-4 text-left">
                    <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important;
      border: none !important;">
                      <span>款式广场</span>
                      <ChevronDownIcon class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                    </PopoverButton>
  
                    <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                      <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form class="space-y-4">
                        <div class="flex items-center">
                         <a href="/mypic"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">我的作品</label></a> 
                        </div>
                        <div class="flex items-center">
                         <a href="/myfav"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">我的收藏</label></a>
                        </div>
                        <div class="flex items-center">
                        <a href="/pic"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">款式广场</label></a>
                        </div>
                      </form>
                      </PopoverPanel>
                    </transition>
                  </Popover>
            </div>
            
  
          <div class="flex items-center gap-4">
            <div class="flex items-center gap-2">
              <button type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 pl-1.5 pr-2 h-7 rounded-lg gap-1 text-xs border-none bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)] cursor-pointer" aria-haspopup="dialog" aria-expanded="false" aria-controls="radix-:r4r:" data-state="closed" data-type="primary">
              <div class="flex items-center h-4 w-4">
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.232 2.287A.75.75 0 0 1 13.75 3v6.25H19a.75.75 0 0 1 .607 1.191l-8 11a.75.75 0 0 1-1.357-.44v-6.25H5a.75.75 0 0 1-.607-1.192l8-11a.75.75 0 0 1 .839-.272Z" fill="currentColor"></path>
                </svg>
              </div>
              <span>{{picnum.value}}</span>
            </button> 
            </div>
            <div class="h-4 w-px bg-[var(--bg-shade)]"></div>
            <div class="flex items-center gap-2">
              <Popover class="relative inline-block px-4 text-left">
              <PopoverButton class="group inline-flex justify-center text-sm font-medium text-gray-300 bg-gray-700 hover:bg-gray-900 hover:text-white" style="outline: none !important;
    border: none !important;">
             <button type="button" class="select-none whitespace-nowrap font-medium transition duration-100 ease-out focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 pl-2 pr-3 h-8 gap-1.5 text-sm border border-solid border-[var(--bg-border)] bg-[var(--bg-base-hover)] text-[var(--label-base)] hover:bg-[var(--bg-shade)] hover:text-[var(--label-title)] focus:ring-[var(--label-title)] cursor-pointer z-50 flex items-center justify-center focus:outline-none rounded-full" data-state="closed" data-type="secondary">
           
              会员中心
            </button> 
         
            </PopoverButton>
                <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-gray-700 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <form class="space-y-4">
                        <div class="flex items-center">
                       <a href="/profile"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">会员中心</label></a> 
                      </div>
                      <div class="flex items-center">
                       <a href="/repassword"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">修改密码</label></a>
                      </div>
                      <div class="flex items-center">
                      <a href="/conlog"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">消费记录</label></a>
                      </div>
                      <div class="flex items-center">
                      <a href="/?ac=loginout"><label class="px-2 py-2 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-900 hover:text-white">退出登录</label></a>
                      </div>
                    </form>
                    </PopoverPanel>
                </transition>
            </Popover>
            </div>
          </div>
        </header>
        <div class="relative flex h-full max-h-[calc(100%-48px)] w-full justify-end">
          <!--左边菜单 start  <div class="w-17 mt-4 ml-4 mb-4 min-w-[288px] max-w-[368px] border-[0.5px] flex-col items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md">-->
        <div class="absolute inset-y-4 left-4 z-10 flex w-[16%] min-w-[308px] max-w-[338px] flex-col items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md" data-projection-id="9" style="opacity: 1; transform: none;">
          <!-- <div class="w-17 mt-4 ml-4 mb-4 min-w-[288px] max-w-[368px] border-[0.5px] flex-col items-center justify-center gap-0 overflow-hidden rounded-2xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] shadow-md" data-projection-id="9" style="opacity: 1; transform: none;"> -->
          <div class="relative flex h-11 min-h-[44px] w-full items-center justify-center border-b-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)]">
           
                <div class="flex items-center gap-2 text-[var(--accent-base)]">
                  
                  <div class="select-none text-base font-medium">款式融合</div>
                </div>
                
              </div>
              <div dir="ltr" class="relative flex h-full w-full overflow-auto" style="position: relative; --radix-scroll-area-corner-width: 0px; --radix-scroll-area-corner-height: 0px;">
                <div class="w-full relative inner" style="overflow: hidden scroll;">
                  <div style="min-width: 300px; display: table;">
                    <div class="flex w-full flex-col gap-3 p-4">
                      <div class="w-full" >
                      <div class=" " style="border-radius: 25px;height:130px;width:130px;float:left">
                           <div class="picbg" style="border-radius: 25px;height:130px;width:130px"></div>
                           <div class="absolute left-4 top-4  " style="width:130px;height:130px;"  v-show="picztshow">
                                  <img :src="thumbnailBasezt64.value" width="130" height="130" style="border-radius: 25px;height:130px">
                            </div>
                      </div>
                      <div class=" " style="border-radius: 25px;height:130px;width:130px;float:right">
                          <div class="picbg" style="border-radius: 25px;height:130px;width:130px"></div>
                             <div class="absolute right-4 top-4  " style="width:130px;height:130px;"  v-show="picshow">
                                  <img :src="thumbnailBase64.value" width="130" height="130" style="border-radius: 25px;height:130px">
                            </div>
                      </div>
                    </div>
                    <div class="w-full h-20">
                      <div class="h-20" style="float:left">
                        <div role="radiogroup" aria-required="false" dir="ltr" class="w-full mb-4" tabindex="0" style="outline: none;">
                        <input aria-label="Brush radius" type="range" min="0" max="2" step="0.1" class="sveltes" style="width:90px" v-model="stepsize">
                        <input type="text" class="yh-input" v-model="stepsize" >  
                        </div>
                        <input type="file" ref="fileInputzt" style="display: none" @change="handleFileztInput"/>
                        <span class="bg-[var(--accent-base)] text-[var(--accent-label)] px-3 py-2 rounded-md text-sm font-medium cursor-pointer" @click="openFileztInput">点击上传主图</span>
                      </div>
                      <div class="h-20" style="float:right">
                        <div role="radiogroup" aria-required="false" dir="ltr" class="w-full mb-4 grid-cols-2" tabindex="0" style="outline: none;">
                        <input aria-label="Brush radius" type="range" min="0" max="2" step="0.1" class="sveltes" style="width:90px" v-model="stepyssize">
                        <input type="text" class="yh-input" v-model="stepyssize" >
                        </div>
                        <input type="file" ref="fileInput" style="display: none" @change="handleFileInput"/>
                        <span class="bg-[var(--accent-base)] text-[var(--accent-label)] px-3 py-2 rounded-md text-sm font-medium cursor-pointer " @click="openFileInput">点击上传元素图</span>
                      </div>
                    </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              

              <div class="flex w-full flex-col items-center justify-center gap-3 border-t-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-4 py-3">
               
                <button type="button" class="inline-flex select-none items-center justify-center whitespace-nowrap font-medium transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[var(--bg-base)] active:opacity-70 pl-3 pr-4 h-10 rounded-xl gap-2 w-full text-base border-none bg-[var(--accent-base)] text-[var(--accent-label)] hover:bg-[var(--accent-base-hover)] focus:ring-[var(--accent-base)] cursor-pointer" data-type="primary" @click="makepic">
                  <div class="flex items-center h-6 w-6">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 4.5a.75.75 0 0 1 .721.544l.813 2.846a3.75 3.75 0 0 0 2.576 2.576l2.846.813a.75.75 0 0 1 0 1.442l-2.846.813a3.75 3.75 0 0 0-2.576 2.576l-.813 2.846a.75.75 0 0 1-1.442 0l-.813-2.846a3.75 3.75 0 0 0-2.576-2.576l-2.846-.813a.75.75 0 0 1 0-1.442l2.846-.813A3.75 3.75 0 0 0 7.466 7.89l.813-2.846A.75.75 0 0 1 9 4.5Zm9-3a.75.75 0 0 1 .728.568l.258 1.036a2.63 2.63 0 0 0 1.91 1.91l1.036.258a.75.75 0 0 1 0 1.456l-1.036.258a2.63 2.63 0 0 0-1.91 1.91l-.258 1.036a.75.75 0 0 1-1.456 0l-.258-1.036a2.624 2.624 0 0 0-1.91-1.91l-1.036-.258a.75.75 0 0 1 0-1.456l1.036-.258a2.625 2.625 0 0 0 1.91-1.91l.258-1.036A.75.75 0 0 1 18 1.5ZM16.5 15a.75.75 0 0 1 .712.513l.394 1.183c.15.447.5.799.948.948l1.183.395a.75.75 0 0 1 0 1.422l-1.183.395a1.5 1.5 0 0 0-.948.948l-.395 1.183a.75.75 0 0 1-1.422 0l-.395-1.183a1.5 1.5 0 0 0-.948-.948l-1.183-.395a.75.75 0 0 1 0-1.422l1.183-.395a1.5 1.5 0 0 0 .948-.948l.395-1.183a.75.75 0 0 1 .71-.513Z" fill="currentColor"></path>
                    </svg>
                  </div>生成图片
                </button>
              </div>
            </div>
          <!--左边菜单 end-->
          <!--中间开始 start-->
          <div class="main-box mb-4" style="margin-left:338px">
            <div class="h-full max-h-[calc(100%-148px)] flex items-center gap-3 rounded-3xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-3 shadow-md mt-4 mb-4" data-projection-id="12" style="opacity: 1; transform: none;">
              <div class="relative flex h-full h-full w-full items-center justify-center" style="">
                <div style="position: relative; width: 100%; height: 100%;  pointer-events: auto; touch-action: none;display:flex;justify-content:center;align-items:center;overflow:hidden" >
                    <!-- <div class="picbg" style="position: absolute;top:15px;left:5px;right:5px;bottom:15px;border-radius: 25px;"></div>
                    <canvas data-engine="three.js r152" style="display: block; width: 100%; height: 100%;overflow:hidden"></canvas> -->
                    <div style="display: block; width: 100%; height: 100%;overflow:hidden"><img :src="hpic.value" style="width:auto;height:auto;max-width:100%;max-height:100%;object-fit:contain;display:block;margin:auto"/></div>
               </div> 
               
               </div>
            </div>
            <!--底部图片列表 start-->
            <div v-show="hpic" class="absolute bottom-4 z-10 flex h-[104px] items-center gap-3 rounded-3xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-sub)] px-3 shadow-md" data-projection-id="12" style="opacity: 1; transform: none;left:23%">
              <div class="relative" >
                <button tabindex="0" class="group relative flex h-20 w-20 flex-col items-center justify-center overflow-hidden rounded-xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-base-hover)] p-2 transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--accent-base)] focus:ring-offset-2 focus:ring-offset-[var(--bg-sub)] active:opacity-70">
                  <img :src="hpic.value" alt="3D View" class="absolute inset-0 h-full w-full object-cover">
                  <div class="absolute inset-0 z-10 h-full w-full bg-[var(--bg-modal-overlay)] opacity-0 transition duration-300 ease-out group-hover:opacity-100"></div>
                  
                </button>
                <div class="pointer-events-none absolute left-0 top-0 z-10 h-20 w-20 rounded-xl ring-2 ring-[var(--accent-base)] ring-offset-2 ring-offset-[var(--bg-sub)] focus:outline-none" data-projection-id="13" style="opacity: 1; transform: none;"></div>
              </div>
              <div class="h-4 w-1 rounded-full bg-[var(--bg-border)]"></div>
              <div class="relative -mx-3 flex h-full flex-col items-center justify-start overflow-hidden">
                <div class="mx-3 flex h-fit flex-col items-center justify-center gap-6 overflow-visible py-3" data-projection-id="14" style="transform: none;">
                  <div class="flex items-center gap-3 overflow-visible focus:outline-none">
                    <button v-for="(item,index) in piclist" tabindex="0" class="group relative flex h-20 w-20 flex-col items-center justify-center overflow-hidden rounded-xl border-[0.5px] border-[var(--bg-border)] bg-[var(--bg-base-hover)] p-2 transition duration-100 ease-out focus:outline-none focus:ring-2 focus:ring-[var(--accent-base)] focus:ring-offset-2 focus:ring-offset-[var(--bg-sub)] active:opacity-70" @click="clickpic(item.bigpath)">
                      <img :src="item.picpath" :alt="item.picname" class="absolute inset-0 h-full w-full object-cover">
                      <div class="absolute inset-0 z-10 h-full w-full bg-[var(--bg-modal-overlay)] opacity-0 transition duration-300 ease-out group-hover:opacity-100"></div>
                    </button>
                    
                  </div>
                </div>
              </div>
            </div>
                    <!--底部图片列表 end-->
          </div>
          <!--中间开始 end-->
        </div>
      </main>
      <div class="flex fixed items-center p-6 max-w sm mx-auto bg-gray-200 rounded-lg space-x-4 a z-10 " style="left:45%;top:40%" v-show="onshow.value">
          <div>
            <div class="text-xl font-medium text-black">{{message}}</div>
            <p class="text-slate-500">
              <button type="button" class="bg-white mr-4 w-32 py-2 px-3 mt-1 rounded-md border border-gray-300" @click="clicle">确 定</button>
            </p>
          </div>
     </div>
      </div>
    </template>
    <style>
    [data-radix-scroll-area-viewport]{scrollbar-width:none;-ms-overflow-style:none;-webkit-overflow-scrolling:touch;}[data-radix-scroll-area-viewport]::-webkit-scrollbar{display:none}
    
    </style>
    
    <script setup>
    import { styletoimg,vitoken,picinfo,userinfo } from '../api/ajaxApi';
    import {setCookie,getCookie,delCookie} from '../utils/cookieok';
    import {Msg} from '@/utils/tools';
    import { RadioGroup, RadioGroupLabel, RadioGroupOption,Popover, PopoverButton, PopoverGroup, PopoverPanel,Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
    import {
    ArrowPathIcon,
    Bars3Icon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    DocumentChartBarIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
    } from '@heroicons/vue/24/outline'
    import { useRouter } from 'vue-router'
    const router = useRouter()
    const route = router.value;
    import { ChevronDownIcon } from '@heroicons/vue/20/solid'
    import {
        ref,
        onMounted, // 组件加载完成时调用
        onUpdated, // 组件更新完成时调用
        onUnmounted, // 组件卸载完成时调用
        onBeforeMount, // 组件加载前时调用
        onBeforeUpdate, // 组件更新前时调用
        onBeforeUnmount, // 组件卸载前时调用
        onActivated, // 组件激活时时调用
        onDeactivated, // 组件失活时时调用
        onErrorCaptured, // 捕获到后代组件的错误时调用
        onRenderTracked, // 状态跟踪，(组件更新时，跟踪组件里边每个值和方法的变化)
        onRenderTriggered, // 状态触发，(虚拟 DOM 重新渲染被触发时调用,此事件告诉你是什么操作触发了重新渲染，以及该操作的目标对象和键)
        onServerPrefetch, // 服务器上被渲染之前调用
    } from "vue";
    
    const selectedmaterial=ref(0); // 存储所选值的变量元素
   
    const navigation = [
    { name: '首页', href: '/', current: false }, 
    { name: '图文创款', href: '/txttoimg', current: false }, 
    { name: '款式再创', href: '/imgtoimg', current: false }, 
    { name: '款式融合', href: '/styletoimg', current: true }, 
    // { name: '手稿配色', href: '/cannytoimg', current: false },
    // { name: '图转线稿', href: '/imgtocanny', current: false }, 
    // { name: '局部重绘', href: '/extratoimg', current: false },
    { name: '换色换料', href: '/colortoimg', current: false },
    { name: '智能抠图', href: '/rembg', current: false },
   ]
   
    let thumbnailBase64=ref('');
    let thumbnailBasezt64=ref('');
    let picshow=ref(false);
    let picztshow=ref(false);
    let stepsize=ref(1.5);
    let stepyssize=ref(0.7)
    const onshow=ref(false);
    const message=ref("");
    const picnum=ref("");
    function clicle(){
     onshow.value=!onshow.value
    }
    function delpic(){
      picshow.value=!picshow.value;
    }
    function deltxt(){
    prompt.value=''
    }
   //图片上传处理开始
  const fileInput = ref(null);
  const fileInputzt = ref(null);
 
  const handleDrop = (event) =>{
    const file = event.dataTransfer.files[0];
    var image = new Image();
    console.log(file.type)
   if(file.type != 'image/png'&&file.type != 'image/jpeg'&&file.type != 'image/gif'&&file.type != 'image/webp'){
          return false;
     }
    
   if(false){}else{
        image.src = window.URL.createObjectURL(file);
        image.onload = function(){
          var width = image.width,height = image.height
            if (width > 1200 || height > 1200) {
                const maxDimension = 1200;
                // 按比例缩放
                if (width > height) {
                    height = height * (maxDimension / width);
                    width = maxDimension;
                } else {
                    width = width * (maxDimension / height);
                    height = maxDimension;
                }
            }
            // 默认图片质量为0.7，quality值越小，所绘制出的图像越模糊
            var quality = 1;
            //生成canvas
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // 创建属性节点
            var anw = document.createAttribute("width");
            anw.nodeValue = width;
            var anh = document.createAttribute("height");
            anh.nodeValue = height;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);

            ctx.drawImage(image, 0, 0, width, height);
            var ext = image.src.substring(image.src.lastIndexOf(".")+1).toLowerCase();//图片格式
            var base64 = canvas.toDataURL("image/"+ext, quality );
            thumbnailBase64.value=ref(base64);
            }
        }
  }
  const openFileInput = () => {
    fileInput.value.click();
   };
   const handleFileInput = (event) => {
   const file = event.target.files[0];
   var image = new Image();
   if(file.type != 'image/png'&&file.type != 'image/jpeg'&&file.type != 'image/gif'&&file.type != 'image/webp'){
          return false;
     }
   if(false){}else{
        image.src = window.URL.createObjectURL(file);
        image.onload = function(){
          var width = image.width,height = image.height
            if (width > 1200 || height > 1200) {
                const maxDimension = 1200;
                // 按比例缩放
                if (width > height) {
                    height = height * (maxDimension / width);
                    width = maxDimension;
                } else {
                    width = width * (maxDimension / height);
                    height = maxDimension;
                }
            }
            // 默认图片质量为0.7，quality值越小，所绘制出的图像越模糊
            var quality = 1;
            //生成canvas
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // 创建属性节点
            var anw = document.createAttribute("width");
            anw.nodeValue = width;
            var anh = document.createAttribute("height");
            anh.nodeValue = height;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);

            ctx.drawImage(image, 0, 0, width, height);
            var ext = image.src.substring(image.src.lastIndexOf(".")+1).toLowerCase();//图片格式
            var base64 = canvas.toDataURL("image/"+ext, quality );
                thumbnailBase64.value=ref(base64);
                picshow.value=ref(true);
                // 回调函数返回base64的值
            }
        }
        
    }

  const openFileztInput = () => {
    fileInputzt.value.click();
   };
   const handleFileztInput = (event) => {
   const file = event.target.files[0];
   var image = new Image();
   if(file.type != 'image/png'&&file.type != 'image/jpeg'&&file.type != 'image/gif'&&file.type != 'image/webp'){
          return false;
     }
   if(false){}else{
        image.src = window.URL.createObjectURL(file);
        image.onload = function(){
          var width = image.width,height = image.height
            if (width > 1200 || height > 1200) {
                const maxDimension = 1200;
                // 按比例缩放
                if (width > height) {
                    height = height * (maxDimension / width);
                    width = maxDimension;
                } else {
                    width = width * (maxDimension / height);
                    height = maxDimension;
                }
            }
            // 默认图片质量为0.7，quality值越小，所绘制出的图像越模糊
            var quality = 1;
            //生成canvas
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            // 创建属性节点
            var anw = document.createAttribute("width");
            anw.nodeValue = width;
            var anh = document.createAttribute("height");
            anh.nodeValue =height;
            canvas.setAttributeNode(anw);
            canvas.setAttributeNode(anh);
            ctx.drawImage(image, 0, 0, width, height);
            var ext = image.src.substring(image.src.lastIndexOf(".")+1).toLowerCase();//图片格式
            var base64 = canvas.toDataURL("image/"+ext, quality );
                 thumbnailBasezt64.value=ref(base64);
                 picztshow.value=ref(true);
                // 回调函数返回base64的值
            }
        }
        
    }
  //图片上传处理结束
  function hrefurl(url){
    router.push(url);
  }

  onMounted(() => {
    document.title = 'AI鞋款创意设计工具';
    binstyle();
  })

  function binstyle(){
    let params={
        token:getCookie("usertoken"),
    }
    vitoken(params).then(res => {
        if (res.success) {
          userinfo(params).then(ress=>{
                 if(ress.success){
                    picnum.value=ref(ress.data.picnum)
                    setCookie('userinfo', ress.data, 7)
                 }
            })
        }
        else
        {
            router.push('/login');
        }
        })
       
  }

  const stheme=ref(0)  
  function selecttheme(theme){
    stheme.value=ref(theme)  //主题
  }
  
  const piclist=ref([])
  const hpic=ref("")
  function clickpic(pic){
    hpic.value=ref(pic)
  }
  function makepic(){
        if(thumbnailBasezt64.value.value==null){
          onshow.value=ref(true)
          message.value="请上传主图！"
        }
        else{
          
             Msg.loading();
             let params={
                b64:thumbnailBasezt64.value.value,
                styleb64:thumbnailBase64.value.value,
                token:getCookie("usertoken")
            }
            styletoimg(params).then(res => {
                Msg.hideLoading();
               if (res.success) {
                    //piclist.value=res.data
                    piclist.value=res.data.concat(piclist.value)
                    hpic.value=ref(res.data[0].bigpath)
                    binstyle()
                }
                else{
                    onshow.value=ref(true)
                    message.value=res.msg
                }
             })
          }
        }
    </script>
    
 